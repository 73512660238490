import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import LayoutSection from "../../components/LayoutSection";
import InputModel from "../../components/InputModel";
import ConfirmationModal from "../../components/ConfirmationModal";
import { createNewStore } from "../../redux/actions/storeActions";
import { toast } from "react-toastify";

function AddStoreScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [eventType, setEventType] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [storeName, setStoreName] = useState("");
  const [storeNameError, setStoreNameError] = useState("");

  const [storeLogo, setStoreLogo] = useState("");
  const [storeLogoValue, setStoreLogoValue] = useState("");
  const [storeLogoError, setStoreLogoError] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const storeAdd = useSelector((state) => state.addStore);
  const { loadingAddStore, errorAddStore, successAddStore } = storeAdd;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successAddStore) {
      setStoreName("");
      setStoreNameError("");
      setStoreLogo("");
      setStoreLogoValue("");
      setStoreLogoError("");
    }
  }, [successAddStore, dispatch]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/stores/">
            <div className="">Stores</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Add</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Add New Store
            </h4>
          </div>
          {/*  */}
          <div className="flex flex-col ">
            <div className=" w-full px-1 py-1">
              <LayoutSection
                title="Store Informations "
                styles="bg-primary text-white font-bold"
              >
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Store Name*"
                    type="text"
                    placeholder=""
                    value={storeName}
                    onChange={(v) => setStoreName(v.target.value)}
                    error={storeNameError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Store Logo"
                    type="file"
                    placeholder=""
                    value={storeLogoValue}
                    onChange={(v) => {
                      setStoreLogo(v.target.files[0]);
                      setStoreLogoValue(v.target.value);
                    }}
                    error={storeLogoError}
                  />
                </div>
                <div className="md:py-2 flex  flex-row justify-end">
                  <button
                    onClick={() => {
                      setEventType("cancel");
                      setIsUpdate("true");
                    }}
                    className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() => {
                      setEventType("add-store");
                      setIsUpdate("true");
                    }}
                    className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1"
                  >
                    Add
                  </button>
                </div>
              </LayoutSection>
            </div>
          </div>
        </div>
      </div>
      {/* add */}
      <ConfirmationModal
        isOpen={isUpdate}
        message={
          eventType === "cancel"
            ? "Are you sure you want to cancel this information?"
            : "Are you sure you want to Add this Store ?"
        }
        onConfirm={async () => {
          if (eventType === "cancel") {
            setIsUpdate(false);
            setEventType("");
            setIsLoading(false);
          } else if (eventType === "add-store") {
            var check = true;

            setStoreNameError("");
            if (storeName === "") {
              check = false;
              setStoreNameError("This field is required.");
            }

            if (check) {
              setIsLoading(true);
              await dispatch(
                createNewStore({
                  store_name: storeName,
                  store_logo: storeLogo,
                })
              ).then(() => {});
              setIsLoading(false);
              setEventType("");
              setIsUpdate(false);
            } else {
              toast.error("Some fields are mandatory please check");
              setIsUpdate(false);
              setEventType("");
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            setEventType("");
            setIsUpdate(false);
          }
        }}
        onCancel={() => {
          setIsUpdate(false);
          setEventType("");
          setIsLoading(false);
        }}
        loadEvent={isLoading}
      />
    </DefaultLayout>
  );
}

export default AddStoreScreen;
