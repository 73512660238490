import { toast } from "react-toastify";
import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  //
  ORDER_ADD_REQUEST,
  ORDER_ADD_SUCCESS,
  ORDER_ADD_FAIL,
  //
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  //
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  //
  ORDER_FIND_REQUEST,
  ORDER_FIND_SUCCESS,
  ORDER_FIND_FAIL,
  //
  ORDER_CONFIRM_REQUEST,
  ORDER_CONFIRM_SUCCESS,
  ORDER_CONFIRM_FAIL,
  //
  ORDER_SHIPPED_REQUEST,
  ORDER_SHIPPED_SUCCESS,
  ORDER_SHIPPED_FAIL,
  //
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  //
  ORDER_ITEMS_REQUEST,
  ORDER_ITEMS_SUCCESS,
  ORDER_ITEMS_FAIL,
  //
  ORDER_LIST_CLIENT_REQUEST,
  ORDER_LIST_CLIENT_SUCCESS,
  ORDER_LIST_CLIENT_FAIL,
  //
  ORDER_CHECKED_REQUEST,
  ORDER_CHECKED_SUCCESS,
  ORDER_CHECKED_FAIL,
  //
} from "../constants/orderConstants";

export const checkedOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CHECKED_REQUEST:
      return { loadingOrderChecked: true };
    case ORDER_CHECKED_SUCCESS:
      toast.success("This Order has been successfully confirmed");
      return {
        loadingOrderChecked: false,
        successOrderChecked: true,
      };
    case ORDER_CHECKED_FAIL:
      toast.error(action.payload);
      return {
        loadingOrderChecked: false,
        successOrderChecked: false,
        errorOrderChecked: action.payload,
      };
    default:
      return state;
  }
};

export const deleteOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return { loadingOrderDelete: true };
    case ORDER_DELETE_SUCCESS:
      toast.success("This order has been deleted");
      return {
        loadingOrderDelete: false,
        successOrderDelete: true,
      };
    case ORDER_DELETE_FAIL:
      toast.error(action.payload);
      return {
        loadingOrderDelete: false,
        successOrderDelete: false,
        errorOrderDelete: action.payload,
      };
    default:
      return state;
  }
};

export const orderListClientReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_CLIENT_REQUEST:
      return { loadingOrdersClient: true, orders: [] };
    case ORDER_LIST_CLIENT_SUCCESS:
      return {
        loadingOrdersClient: false,
        successOrdersClient: true,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case ORDER_LIST_CLIENT_FAIL:
      return {
        loadingOrdersClient: false,
        errorOrdersClient: action.payload,
        successOrdersClient: false,
      };
    default:
      return state;
  }
};

export const updateOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return { loadingOrderUpdate: true };
    case ORDER_UPDATE_SUCCESS:
      toast.success("This Order has been updated");
      return {
        loadingOrderUpdate: false,
        successOrderUpdate: true,
      };
    case ORDER_UPDATE_FAIL:
      toast.error(action.payload);
      return {
        loadingOrderUpdate: false,
        successOrderUpdate: false,
        errorOrderUpdate: action.payload,
      };
    default:
      return state;
  }
};

export const confirmShippedOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_SHIPPED_REQUEST:
      return { loadingOrderShipped: true };
    case ORDER_SHIPPED_SUCCESS:
      toast.success("This Order has been successfully confirmed to SHIPPED");
      return {
        loadingOrderShipped: false,
        successOrderShipped: true,
      };
    case ORDER_SHIPPED_FAIL:
      toast.error(action.payload);
      return {
        loadingOrderShipped: false,
        successOrderShipped: false,
        errorOrderShipped: action.payload,
      };
    default:
      return state;
  }
};

export const itemsOrderReducer = (state = { orderItems: [] }, action) => {
  switch (action.type) {
    case ORDER_ITEMS_REQUEST:
      return { loadingOrderItems: true };
    case ORDER_ITEMS_SUCCESS:
      return {
        loadingOrderItems: false,
        successOrderItems: true,
        orderItems: action.payload.orderitems,
      };
    case ORDER_ITEMS_FAIL:
      return {
        loadingOrderItems: false,
        successOrderItems: false,
        errorOrderItems: action.payload,
      };
    default:
      return state;
  }
};

export const detailOrderReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case ORDER_DETAIL_REQUEST:
      return { loadingOrderDetail: true };
    case ORDER_DETAIL_SUCCESS:
      return {
        loadingOrderDetail: false,
        successOrderDetail: true,
        order: action.payload.order,
      };
    case ORDER_DETAIL_FAIL:
      return {
        loadingOrderDetail: false,
        successOrderDetail: false,
        errorOrderDetail: action.payload,
      };
    default:
      return state;
  }
};

export const confirmOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CONFIRM_REQUEST:
      return { loadingOrderConfirm: true };
    case ORDER_CONFIRM_SUCCESS:
      toast.success("This Order has been successfully confirmed");
      return {
        loadingOrderConfirm: false,
        successOrderConfirm: true,
      };
    case ORDER_CONFIRM_FAIL:
      toast.error(action.payload);
      return {
        loadingOrderConfirm: false,
        successOrderConfirm: false,
        errorOrderConfirm: action.payload,
      };
    default:
      return state;
  }
};

export const createNewOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_ADD_REQUEST:
      return { loadingOrderAdd: true };
    case ORDER_ADD_SUCCESS:
      toast.success("This Order has been successfully added");
      return {
        loadingOrderAdd: false,
        successOrderAdd: true,
      };
    case ORDER_ADD_FAIL:
      toast.error(action.payload);
      return {
        loadingOrderAdd: false,
        successOrderAdd: false,
        errorOrderAdd: action.payload,
      };
    default:
      return state;
  }
};

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loadingOrders: true, orders: [] };
    case ORDER_LIST_SUCCESS:
      return {
        loadingOrders: false,
        successOrders: true,
        orders: action.payload.orders,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case ORDER_LIST_FAIL:
      return {
        loadingOrders: false,
        errorOrders: action.payload,
        successOrders: false,
      };
    default:
      return state;
  }
};
