import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  detailOrder,
  getItemsOrder,
  updateOrder,
} from "../../redux/actions/orderActions";
import DefaultLayout from "../../layouts/DefaultLayout";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { getListStores } from "../../redux/actions/storeActions";
import { toast } from "react-toastify";
import InputModel from "../../components/InputModel";
import LayoutSection from "../../components/LayoutSection";
import { baseURLFile, ReturnStatusValue } from "../../constants";
import ConfirmationModal from "../../components/ConfirmationModal";
import { clientList } from "../../redux/actions/clientActions";
import { productList } from "../../redux/actions/productActions";

function EditOrderScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  let { id } = useParams();

  //
  const [orderType, setOrderType] = useState("NORMAL");
  const [orderTypeError, setOrderTypeError] = useState("");

  const [orderFrom, setOrderFrom] = useState("Morocco");
  const [orderFromError, setOrderFromError] = useState("");

  const [specializedNote, setSpecializedNote] = useState("");
  const [specializedNoteError, setSpecializedNoteError] = useState("");

  const [storeSelect, setStoreSelect] = useState("");
  const [storeSelectError, setStoreSelectError] = useState("");

  const [dateSelect, setDateSelect] = useState("");
  const [dateSelectError, setDateSelectError] = useState("");

  const [orderNumber, setOrderNumber] = useState("");
  const [orderNumberError, setOrderNumberError] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountError, setTotalAmountError] = useState("");

  const [trackingNumber, setTrackingNumber] = useState("");
  const [trackingNumberError, setTrackingNumberError] = useState("");

  const [trackingService, setTrackingService] = useState("");
  const [trackingServiceError, setTrackingServiceError] = useState("");

  const [preparingWeight, setPreparingWeight] = useState("");
  const [preparingWeightError, setPreparingWeightError] = useState("");

  const [preparingSize, setPreparingSize] = useState("");
  const [preparingSizeError, setPreparingSizeError] = useState("");

  const [statusOrder, setStatusOrder] = useState("");
  const [statusOrderError, setStatusOrderError] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientNameError, setClientNameError] = useState("");

  const [clientPhone, setClientPhone] = useState("");
  const [clientPhoneError, setClientPhoneError] = useState("");

  const [clientEmail, setClientEmail] = useState("");
  const [clientEmailError, setClientEmailError] = useState("");

  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingAddressError, setShippingAddressError] = useState("");

  const [orderItemsDelete, setOrderItemsDelete] = useState([]);
  const [orderItemsLast, setOrderItemsLast] = useState([]);
  const [orderItemsLastError, setOrderItemsLastError] = useState("");

  const [orderItemsNew, setOrderItemsNew] = useState([]);
  const [orderItemsNewError, setOrderItemsNewError] = useState("");

  const [productName, setProductName] = useState("");
  const [productNameError, setProductNameError] = useState("");

  const [productImage, setProductImage] = useState("");
  const [productImagev, setProductImagev] = useState("");
  const [productImageError, setProductImageError] = useState("");

  const [productImageUrl, setProductImageUrl] = useState("");
  const [productImageUrlError, setProductImageUrlError] = useState("");

  const [productQuantity, setProductQuantity] = useState(1);
  const [productQuantityError, setProductQuantityError] = useState("");

  const [productPrice, setProductPrice] = useState(0);
  const [productPriceError, setProductPriceError] = useState("");

  const [productVariation, setProductVariation] = useState("");
  const [productVariationError, setProductVariationError] = useState("");

  const [productNote, setProductNote] = useState("");
  const [productNoteError, setProductNoteError] = useState("");

  const [isAddOrder, setIsAddOrder] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  const [clientExist, setClientExist] = useState(false);
  const [clientExistError, setClientExistError] = useState(false);

  const [orderClient, setOrderClient] = useState("");
  const [orderClientError, setOrderClientError] = useState("");

  const [fullAddress, setFullAddress] = useState("");
  const [fullAddressError, setFullAddressError] = useState("");

  const [countryAddress, setCountryAddress] = useState("");
  const [countryAddressError, setCountryAddressError] = useState("");

  const [cityAddress, setCityAddress] = useState("");
  const [cityAddressError, setCityAddressError] = useState("");

  const [stateAddress, setStateAddress] = useState("");
  const [stateAddressError, setStateAddressError] = useState("");

  const [zipCodeAddress, setZipCodeAddress] = useState("");
  const [zipCodeAddressError, setZipCodeAddressError] = useState("");

  const [locationXAddress, setLocationXAddress] = useState("");
  const [locationXAddressError, setLocationXAddressError] = useState("");

  const [locationYAddress, setLocationYAddress] = useState("");
  const [locationYAddressError, setLocationYAddressError] = useState("");

  const [productExist, setProductExist] = useState(true);

  const [productSelect, setProductSelect] = useState("");
  const [productSelectError, setProductSelectError] = useState("");
  const [variationList, setVariationList] = useState([]);
  const [variationSelect, setVariationSelect] = useState("");
  const [variationSelectError, setVariationSelectError] = useState("");

  //

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const orderDetail = useSelector((state) => state.detailOrder);
  const { successOrderDetail, loadingOrderDetail, order, errorOrderDetail } =
    orderDetail;

  const orderItem = useSelector((state) => state.itemsOrder);
  const { successOrderItems, loadingOrderItems, orderItems, errorOrderItems } =
    orderItem;

  const listStore = useSelector((state) => state.storeList);
  const { stores } = listStore;

  const orderUpdate = useSelector((state) => state.updateOrder);
  const { loadingOrderUpdate, errorOrderUpdate, successOrderUpdate } =
    orderUpdate;

  const listClient = useSelector((state) => state.clientList);
  const { clients } = listClient;

  const listProducts = useSelector((state) => state.productList);
  const { products, loadingProductList, errorProductList } = listProducts;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListStores("0"));
      dispatch(clientList("0"));
      dispatch(detailOrder(id));
      dispatch(getItemsOrder(id));
      dispatch(productList("0"));
    }
  }, [navigate, userInfo, dispatch, id]);

  useEffect(() => {
    if (order && order !== null && order !== undefined) {
      setStoreSelect(order.store);
      setDateSelect(order.date);
      setOrderNumber(order.order_number);
      setTotalAmount(order.total_amount);
      setClientName(order.client_name);
      setClientPhone(order.client_phone);
      setClientEmail(order.client_email);
      setShippingAddress(order.shipping_address);
      setTrackingNumber(order.tracking_number);
      setStatusOrder(order.status);
      setOrderClient(order.client);
      setClientExist(order.client !== null);
      setOrderFrom(order.order_from ?? "Morocco");
      setOrderType(order.order_type ?? "NORMAL");
      setSpecializedNote(order.specialized_note ?? "");
      setPreparingSize(order.preparing_size ?? "");
      setPreparingWeight(order.preparing_weight ?? "");

      if (order.shipping_address?.id) {
        setFullAddress(order.shipping_address?.address);
        setCountryAddress(order.shipping_address?.country);
        setCityAddress(order.shipping_address?.city);
        setStateAddress(order.shipping_address?.state);
        setZipCodeAddress(order.shipping_address?.zip_code);
        setLocationXAddress(order.shipping_address?.location_x);
        setLocationYAddress(order.shipping_address?.location_y);
      }
    }
  }, [order]);

  useEffect(() => {
    if (successOrderUpdate) {
      setOrderType("NORMAL");
      setOrderFrom("Morocco");
      setStoreSelect("");
      setStoreSelectError("");

      setSpecializedNote("");
      setSpecializedNoteError("");

      setDateSelect("");
      setDateSelectError("");

      setOrderNumber("");
      setOrderNumberError("");

      setTotalAmount(0);
      setTotalAmountError("");

      setTrackingNumber("");
      setTrackingNumberError("");

      setStatusOrder("");
      setStatusOrderError("");

      setClientName("");
      setClientNameError("");

      setClientPhone("");
      setClientPhoneError("");

      setClientEmail("");
      setClientEmailError("");

      setShippingAddress("");
      setShippingAddressError("");

      setOrderItemsDelete([]);
      setOrderItemsLast([]);
      setOrderItemsLastError("");

      setOrderItemsNew([]);
      setOrderItemsNewError("");

      setProductName("");
      setProductNameError("");

      setProductImage("");
      setProductImageUrl("");
      setProductImageUrlError("");
      setProductImagev("");
      setProductImageError("");

      setProductQuantity(1);
      setProductQuantityError("");

      setProductPrice(0);
      setProductPriceError("");

      setClientExist(false);
      setClientExistError("");
      setOrderClient("");
      setOrderClientError("");

      setProductVariation("");
      setProductVariationError("");

      setProductNote("");
      setProductNoteError("");

      dispatch(getListStores("0"));
      dispatch(detailOrder(id));
      dispatch(getItemsOrder(id));
    }
  }, [successOrderUpdate]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/orders/">
            <div className="">Orders</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Detail</div>
        </div>
        {/*  */}
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Update Order N°:{id}
            </h4>
          </div>
          {loadingOrderDetail ? (
            <Loader />
          ) : errorOrderDetail ? (
            <Alert type="error" message={errorOrderDetail} />
          ) : order ? (
            <>
              <div className="flex md:flex-row flex-col ">
                <div className="md:w-1/2 w-full px-1 py-1">
                  <LayoutSection
                    title="Order Info"
                    styles="bg-primary text-white font-bold"
                  >
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Type*"
                        type="select"
                        placeholder=""
                        value={orderType}
                        onChange={(v) => {
                          setOrderType(v.target.value);
                        }}
                        error={orderTypeError}
                        options={[
                          {
                            value: "NORMAL",
                            label: "Normal",
                          },
                          {
                            value: "PROBLEM",
                            label: "Problem",
                          },
                        ]}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="From*"
                        type="select"
                        placeholder=""
                        value={orderFrom}
                        onChange={(v) => {
                          setOrderFrom(v.target.value);
                        }}
                        error={orderFromError}
                        options={[
                          {
                            value: "Morocco",
                            label: "Morocco",
                          },
                          {
                            value: "Other",
                            label: "Other",
                          },
                        ]}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Store*"
                        type="select"
                        placeholder=""
                        value={storeSelect}
                        onChange={(v) => {
                          setStoreSelect(v.target.value);
                        }}
                        error={storeSelectError}
                        options={stores?.map((store) => ({
                          value: store.id,
                          label: store.store_name,
                        }))}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Date*"
                        type="date"
                        placeholder=""
                        value={dateSelect}
                        onChange={(v) => {
                          setDateSelect(v.target.value);
                        }}
                        error={dateSelectError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Order Number*"
                        type="text"
                        placeholder=""
                        value={orderNumber}
                        onChange={(v) => {
                          setOrderNumber(v.target.value);
                        }}
                        error={orderNumberError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Total Amount*"
                        type="number"
                        isPrice={true}
                        placeholder=""
                        value={totalAmount}
                        onChange={(v) => {
                          setTotalAmount(v.target.value);
                        }}
                        error={totalAmountError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Status*"
                        type="select"
                        placeholder=""
                        value={statusOrder}
                        onChange={(v) => {
                          setStatusOrder(v.target.value);
                        }}
                        error={statusOrderError}
                        options={[
                          "PROCESSING", //  COMMANDE JDIDA
                          "PREPARING", // WAJDA
                          "SHIPPED", // TQAD TRACKING
                          "CANCELED", // TCANCELAT
                          "RETURNED", // REJ3AT
                          // "RECEIVED",
                        ]?.map((statu) => ({
                          value: statu,
                          label: ReturnStatusValue(statu),
                        }))}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Tracking Service"
                        type="select"
                        placeholder=""
                        value={trackingService}
                        onChange={(v) => {
                          setTrackingService(v.target.value);
                        }}
                        error={trackingServiceError}
                        options={[
                          {
                            value: "DHL",
                            label: "DHL",
                          },
                          {
                            value: "FEDEX",
                            label: "FEDEX",
                          },
                          {
                            value: "ARAMEX",
                            label: "ARAMEX",
                          },
                          {
                            value: "UPS",
                            label: "UPS",
                          },
                          {
                            value: "USPS",
                            label: "USPS",
                          },
                        ]}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Tracking Number"
                        type="text"
                        placeholder=""
                        value={trackingNumber}
                        onChange={(v) => {
                          setTrackingNumber(v.target.value);
                        }}
                        error={trackingNumberError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Size"
                        type="text"
                        placeholder=""
                        value={preparingSize}
                        onChange={(v) => {
                          setPreparingSize(v.target.value);
                        }}
                        error={preparingSizeError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Weight"
                        type="number"
                        placeholder=""
                        value={preparingWeight}
                        onChange={(v) => {
                          setPreparingWeight(v.target.value);
                        }}
                        error={preparingWeightError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Specialized Note"
                        type="textarea"
                        placeholder=""
                        value={specializedNote}
                        onChange={(v) => {
                          setSpecializedNote(v.target.value);
                        }}
                        error={specializedNoteError}
                      />
                    </div>
                  </LayoutSection>
                </div>
                <div className="md:w-1/2 w-full px-1 py-1">
                  <LayoutSection
                    title="Customer Info"
                    styles="bg-danger text-white font-bold"
                  >
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Client Exist"
                        type="select"
                        placeholder=""
                        value={clientExist}
                        onChange={(v) => {
                          setClientExist(v.target.value === "true");
                          setClientEmail("");
                          setClientName("");
                          setClientPhone("");
                        }}
                        error={""}
                        options={[
                          {
                            value: "false",
                            label: "No",
                          },
                          {
                            value: "true",
                            label: "Yes",
                          },
                        ]}
                      />
                    </div>

                    {clientExist === true ? (
                      <div className="md:py-2 md:flex ">
                        <InputModel
                          label="Client*"
                          type="searchselect"
                          placeholder=""
                          value={clients?.find(
                            (option) => option.value === orderClient
                          )}
                          onChange={(v) => {
                            setOrderClient(v.value);
                            setClientEmail("");
                            setClientName("");
                            setClientPhone("");

                            if (v.value !== "") {
                              var filter = clients?.find(
                                (client) =>
                                  parseInt(v.value) === parseInt(client.id)
                              );
                              if (filter !== null && filter !== undefined) {
                                setClientEmail(filter.email || "");
                                setClientName(filter.full_name || "");
                                setClientPhone(filter.phone || "");
                              }
                            }
                          }}
                          error={orderClientError}
                          options={clients?.map((client) => ({
                            value: client.id,
                            label: client.full_name,
                          }))}
                        />
                      </div>
                    ) : null}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Client Name*"
                        type="text"
                        placeholder=""
                        value={clientName}
                        disabled={clientExist}
                        onChange={(v) => {
                          setClientName(v.target.value);
                        }}
                        error={clientNameError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Client Email*"
                        type="text"
                        placeholder=""
                        value={clientEmail}
                        disabled={clientExist}
                        onChange={(v) => {
                          setClientEmail(v.target.value);
                        }}
                        error={clientEmailError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Client Phone"
                        type="text"
                        placeholder=""
                        value={clientPhone}
                        disabled={clientExist}
                        onChange={(v) => {
                          setClientPhone(v.target.value);
                        }}
                        error={clientPhoneError}
                      />
                    </div>
                  </LayoutSection>
                </div>
              </div>
              <div className="flex md:flex-row flex-col ">
                <div className="w-full px-1 py-1">
                  <LayoutSection
                    title="Shipping Informations"
                    styles="bg-primary text-white font-bold"
                  >
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Full Address*"
                        type="text"
                        placeholder=""
                        value={fullAddress}
                        onChange={(v) => {
                          setFullAddress(v.target.value);
                        }}
                        error={fullAddressError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Country Address"
                        type="text"
                        placeholder=""
                        value={countryAddress}
                        onChange={(v) => {
                          setCountryAddress(v.target.value);
                        }}
                        error={countryAddressError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="City Address"
                        type="text"
                        placeholder=""
                        value={cityAddress}
                        onChange={(v) => {
                          setCityAddress(v.target.value);
                        }}
                        error={cityAddressError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="State Address"
                        type="text"
                        placeholder=""
                        value={stateAddress}
                        onChange={(v) => {
                          setStateAddress(v.target.value);
                        }}
                        error={stateAddressError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Zip Code Address"
                        type="text"
                        placeholder=""
                        value={zipCodeAddress}
                        onChange={(v) => {
                          setZipCodeAddress(v.target.value);
                        }}
                        error={zipCodeAddressError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Location X"
                        type="text"
                        placeholder=""
                        value={locationXAddress}
                        onChange={(v) => {
                          setLocationXAddress(v.target.value);
                        }}
                        error={locationXAddressError}
                      />
                      <InputModel
                        label="Location Y"
                        type="text"
                        placeholder=""
                        value={locationYAddress}
                        onChange={(v) => {
                          setLocationYAddress(v.target.value);
                        }}
                        error={locationYAddressError}
                      />
                    </div>
                  </LayoutSection>
                </div>
              </div>
              <div className="flex md:flex-row flex-col ">
                <div className="w-full px-1 py-1">
                  <LayoutSection
                    title="Order Items"
                    styles="bg-primary text-white font-bold"
                  >
                    <div className="flex md:flex-row flex-col ">
                      <div className="md:w-1/2 w-full px-1 py-1">
                        <div className="md:py-2 md:flex ">
                          <InputModel
                            label="Product Exist"
                            type="select"
                            placeholder=""
                            value={productExist}
                            onChange={(v) => {
                              setProductExist(v.target.value === "true");
                              setProductSelect("");
                              setProductSelectError("");
                              setVariationSelect("");
                              setVariationSelectError("");
                              setVariationList([]);
                            }}
                            error={""}
                            options={[
                              {
                                value: "true",
                                label: "Yes",
                              },
                              {
                                value: "false",
                                label: "No",
                              },
                            ]}
                          />
                        </div>
                        {productExist === true ? (
                          <div className="md:py-2 md:flex ">
                            <InputModel
                              label="Product*"
                              type="searchselect"
                              placeholder=""
                              zindex="z-999"
                              value={products?.find(
                                (option) => option.value === productSelect
                              )}
                              onChange={(v) => {
                                setProductSelect(v.value);
                                if (v.value !== "") {
                                  var filter = products?.find(
                                    (prod) =>
                                      parseInt(v.value) === parseInt(prod.id)
                                  );
                                  if (filter !== null && filter !== undefined) {
                                    setVariationList(filter.variation_list);
                                    setProductName(filter.product_name);
                                    setProductImage(filter.product_image);
                                    if (filter.is_variation) {
                                      setProductPrice(0);
                                    } else {
                                      setProductPrice(filter.product_price);
                                    }
                                  }
                                }
                              }}
                              error={productSelectError}
                              options={products?.map((prod) => ({
                                value: prod.id,
                                label: prod.product_name,
                              }))}
                            />
                          </div>
                        ) : null}
                        {productExist === true && variationList.length !== 0 ? (
                          <div className="md:py-2 md:flex ">
                            <InputModel
                              label="Variation*"
                              type="searchselect"
                              placeholder=""
                              value={variationList?.find(
                                (option) => option.value === variationSelect
                              )}
                              onChange={(v) => {
                                setVariationSelect(v.value);
                                if (v.value !== "") {
                                  var filter = variationList?.find(
                                    (variation) =>
                                      parseInt(v.value) ===
                                      parseInt(variation.id)
                                  );
                                  if (filter !== null && filter !== undefined) {
                                    // setVariationList(filter.variation_list);
                                    setProductVariation(filter.variation_price);
                                    setProductVariation(filter.variation_name);
                                    setProductPrice(filter.variation_price);
                                    // setClientEmail(filter.email || "");
                                    // setClientName(filter.full_name || "");
                                    // setClientPhone(filter.phone || "");
                                  }
                                }
                              }}
                              error={variationSelectError}
                              options={variationList?.map((variation) => ({
                                value: variation.id,
                                label:
                                  variation.variation_name +
                                  " (" +
                                  parseFloat(variation.variation_price).toFixed(
                                    2
                                  ) +
                                  ")",
                              }))}
                            />
                          </div>
                        ) : null}
                        <div className="md:py-2 md:flex ">
                          <InputModel
                            label="Image"
                            type="file"
                            disabled={productExist}
                            placeholder=""
                            value={productImagev}
                            onChange={(v) => {
                              setProductImage(v.target.files[0]);
                              setProductImagev(v.target.value);
                            }}
                            error={productImageError}
                          />
                        </div>
                        <div className="md:py-2 md:flex ">
                          <InputModel
                            label="Product Image Url"
                            type="text"
                            placeholder=""
                            disabled={productExist}
                            value={productImageUrl}
                            onChange={(v) => {
                              setProductImageUrl(v.target.value);
                            }}
                            error={productImageUrlError}
                          />
                        </div>
                        <div className="md:py-2 md:flex ">
                          <InputModel
                            label="Product Name*"
                            type="text"
                            placeholder=""
                            disabled={productExist}
                            value={productName}
                            onChange={(v) => {
                              setProductName(v.target.value);
                            }}
                            error={productNameError}
                          />
                        </div>
                        <div className="md:py-2 md:flex ">
                          <InputModel
                            label="Product Price*"
                            type="number"
                            isPrice={true}
                            disabled={productExist}
                            placeholder=""
                            value={productPrice}
                            onChange={(v) => {
                              setProductPrice(v.target.value);
                            }}
                            error={productPriceError}
                          />
                        </div>
                        <div className="md:py-2 md:flex ">
                          <InputModel
                            label="Quantity*"
                            type="number"
                            placeholder=""
                            value={productQuantity}
                            onChange={(v) => {
                              setProductQuantity(v.target.value);
                            }}
                            error={productQuantityError}
                          />
                        </div>
                        <div className="md:py-2 md:flex ">
                          <InputModel
                            label="Variation"
                            type="textarea"
                            placeholder=""
                            disabled={productExist}
                            value={productVariation}
                            onChange={(v) => {
                              setProductVariation(v.target.value);
                            }}
                            error={productVariationError}
                          />
                        </div>
                        <div className="md:py-2 md:flex ">
                          <InputModel
                            label="Product Note"
                            type="textarea"
                            placeholder=""
                            value={productNote}
                            onChange={(v) => {
                              setProductNote(v.target.value);
                            }}
                            error={productNoteError}
                          />
                        </div>
                        <div className="md:py-2 flex ">
                          <button
                            onClick={() => {
                              var check = true;
                              setProductImageError("");
                              setProductImageUrlError("");
                              setProductNameError("");
                              setProductPriceError("");
                              setProductQuantityError("");
                              setProductVariationError("");
                              setProductSelectError("");
                              setVariationSelectError("");

                              if (productExist && productSelect === "") {
                                setProductSelectError(
                                  "This field is required."
                                );
                                check = false;
                              }

                              if (
                                productExist &&
                                variationList.length !== 0 &&
                                variationSelect === ""
                              ) {
                                setVariationSelectError(
                                  "This field is required."
                                );
                                check = false;
                              }

                              if (
                                !productExist &&
                                productImage === "" &&
                                productImageUrl === ""
                              ) {
                                setProductImageError("This field is required.");
                                check = false;
                              }
                              if (productName === "") {
                                setProductNameError("This field is required.");
                                check = false;
                              }

                              if (productQuantity === "") {
                                setProductQuantityError(
                                  "This field is required."
                                );
                                check = false;
                              } else {
                                if (
                                  isNaN(Number(productQuantity)) ||
                                  !Number.isInteger(Number(productQuantity)) ||
                                  Number(productQuantity) <= 0
                                ) {
                                  setProductQuantityError(
                                    "The value of this field is not a positive number."
                                  );
                                  check = false;
                                }
                              }

                              if (productPrice === "") {
                                setProductPriceError("This field is required.");
                                check = false;
                              } else {
                                if (
                                  isNaN(Number(productPrice)) ||
                                  Number(productPrice) <= 0
                                ) {
                                  setProductPriceError(
                                    "The value of this field is not a positive number."
                                  );
                                  check = false;
                                }
                              }

                              if (check) {
                                const newItem = {
                                  product_name: productName,
                                  product_image: productExist
                                    ? productImagev
                                    : productImage,
                                  product_image_url: productImageUrl,
                                  product_price: productPrice,
                                  product_quantity: productQuantity,
                                  product_variation: productVariation,
                                  product_exist: productExist,
                                  product_select: productSelect,
                                  variation_select: variationSelect,
                                  product_note: productNote,
                                };

                                setOrderItemsNew([...orderItemsNew, newItem]);
                                setProductImage("");
                                setProductName("");
                                setProductQuantity(1);
                                setProductPrice(0);
                                setProductVariation("");
                                setProductImagev("");
                                setProductImageUrl("");
                              } else {
                                toast.error(
                                  "Some fields are mandatory please check"
                                );
                              }
                            }}
                            className="border border-black text-black  text-xs  font-bold px-6 py-2 rounded-full mx-1"
                          >
                            Add Item
                          </button>
                        </div>
                      </div>
                      <div className="md:w-1/2 w-full px-1 py-1">
                        <div className="font-bold ">Items</div>
                        <div>
                          {orderItems?.map((item, index) => (
                            <>
                              {!orderItemsDelete.includes(item.id) ? (
                                <div
                                  key={index}
                                  className="flex flex-row my-2 items-center border-b py-3"
                                >
                                  <div className="mx-1">
                                    <div
                                      onClick={() => {
                                        setOrderItemsDelete([
                                          ...orderItemsDelete,
                                          item.id,
                                        ]);

                                        // setEventType("delete");
                                        // setLoadEvent(false);
                                        // setOrderId(order.id);
                                        // setIsOpen(true);
                                      }}
                                      className="mx-1 delete-class cursor-pointer"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  {item.product_image ? (
                                    <img
                                      src={baseURLFile + item.product_image}
                                      alt={item.product_name}
                                      className="w-10 h-10 mx-1"
                                    />
                                  ) : (
                                    <img
                                      src={item.product_image_url}
                                      alt={item.product_name}
                                      className="w-10 h-10 mx-1"
                                    />
                                  )}
                                  <div className="mx-1 flex-1">
                                    {/* Replace with the appropriate item properties you want to display */}
                                    <p className="text-sm font-bold">
                                      {item.product_quantity} X{" "}
                                      {item.product_name}
                                    </p>
                                    <p className="text-sm">
                                      {parseFloat(item.product_price).toFixed(
                                        2
                                      )}
                                    </p>
                                    <p className="text-sm">
                                      {item.product_variation}{" "}
                                    </p>
                                    <p className="text-sm text-danger">
                                      {item.product_note}{" "}
                                    </p>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          ))}
                        </div>

                        <div>
                          {orderItemsNew?.map((item, index) => (
                            <div
                              key={index}
                              className="flex flex-row my-2 items-center border-b py-3"
                            >
                              <div className="mx-1">
                                <div
                                  onClick={() => {
                                    const updatedItems = [
                                      ...orderItemsNew.slice(0, index),
                                      ...orderItemsNew.slice(index + 1),
                                    ];
                                    setOrderItemsNew(updatedItems);
                                    // setEventType("delete");
                                    // setLoadEvent(false);
                                    // setOrderId(order.id);
                                    // setIsOpen(true);
                                  }}
                                  className="mx-1 delete-class cursor-pointer"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                    />
                                  </svg>
                                </div>
                              </div>
                              {item.product_image ? (
                                <img
                                  src={URL.createObjectURL(item.product_image)}
                                  alt={item.product_name}
                                  className="w-10 h-10 mx-1"
                                />
                              ) : (
                                <img
                                  src={item.product_image_url}
                                  alt={item.product_name}
                                  className="w-10 h-10 mx-1"
                                />
                              )}
                              <div className="mx-1 flex-1">
                                {/* Replace with the appropriate item properties you want to display */}
                                <p className="text-sm font-bold">
                                  {item.product_quantity} X {item.product_name}
                                </p>
                                <p className="text-sm">
                                  {parseFloat(item.product_price).toFixed(2)}
                                </p>
                                <p className="text-sm">
                                  {item.product_variation}{" "}
                                </p>
                                <p className="text-sm text-danger">
                                  {item.product_note}{" "}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </LayoutSection>
                </div>
              </div>
              <div className="my-2 flex flex-row items-center justify-end">
                <button
                  onClick={() => {
                    setEventType("cancel");
                    setIsAddOrder(true);
                  }}
                  className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
                >
                  Cancel
                </button>
                <button
                  onClick={async () => {
                    var check = true;
                    setOrderTypeError("");
                    setOrderFromError("");
                    setStoreSelectError("");
                    setDateSelectError("");
                    setOrderNumberError("");
                    setTotalAmountError("");

                    setClientNameError("");
                    setClientPhoneError("");
                    setClientEmailError("");
                    setShippingAddressError("");

                    setClientExistError("");

                    if (orderType === "") {
                      setOrderTypeError("This field is required.");
                      check = false;
                    }

                    if (orderFrom === "") {
                      setOrderFromError("This field is required.");
                      check = false;
                    }

                    if (storeSelect === "") {
                      setStoreSelectError("This field is required.");
                      check = false;
                    }
                    if (dateSelect === "") {
                      setDateSelectError("This field is required.");
                      check = false;
                    }
                    if (orderNumber === "") {
                      setOrderNumberError("This field is required.");
                      check = false;
                    }
                    if (totalAmount === "") {
                      setTotalAmountError("This field is required.");
                      check = false;
                    } else {
                      if (
                        isNaN(Number(totalAmount)) ||
                        Number(totalAmount) <= 0
                      ) {
                        setTotalAmountError(
                          "The value of this field is not a positive number."
                        );
                        check = false;
                      }
                    }

                    if (clientExist === true && orderClient === "") {
                      setClientExistError("This field is required.");
                      check = false;
                    }

                    if (clientName === "") {
                      setClientNameError("This field is required.");
                      check = false;
                    }
                    if (clientEmail === "") {
                      setClientEmailError("This field is required.");
                      check = false;
                    }

                    if (shippingAddress === "") {
                      setShippingAddressError("This field is required.");
                      check = false;
                    }

                    if (check) {
                      setEventType("add");
                      setIsAddOrder(true);
                    } else {
                      toast.error("Some fields are mandatory please check");
                    }
                  }}
                  className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                    />
                  </svg>
                  Update
                </button>
              </div>
            </>
          ) : null}
        </div>
        <ConfirmationModal
          isOpen={isAddOrder}
          message={
            eventType === "cancel"
              ? "Are you sure you want cancel this information ?"
              : "Are you sure you want update this Order ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setOrderType("NORMAL");
              setOrderTypeError("");

              setOrderFrom("Morocco");
              setOrderFromError("");

              setSpecializedNote("");
              setSpecializedNoteError("");

              setStoreSelect("");
              setStoreSelectError("");

              setDateSelect("");
              setDateSelectError("");

              setOrderNumber("");
              setOrderNumberError("");

              setTotalAmount(0);
              setTotalAmountError("");

              setTrackingNumber("");
              setTrackingNumberError("");

              setStatusOrder("");
              setStatusOrderError("");

              setClientName("");
              setClientNameError("");

              setClientPhone("");
              setClientPhoneError("");

              setClientEmail("");
              setClientEmailError("");

              setShippingAddress("");
              setShippingAddressError("");

              setOrderItemsDelete([]);
              setOrderItemsLast([]);
              setOrderItemsLastError("");

              setOrderItemsNew([]);
              setOrderItemsNewError("");

              setProductName("");
              setProductNameError("");

              setProductImage("");
              setProductImageUrl("");
              setProductImageUrlError("");
              setProductImagev("");
              setProductImageError("");

              setProductQuantity(1);
              setProductQuantityError("");

              setProductPrice(0);
              setProductPriceError("");

              setProductVariation("");
              setProductVariationError("");
              dispatch(getListStores("0"));
              dispatch(detailOrder(id));
              dispatch(getItemsOrder(id));

              setIsAddOrder(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                updateOrder(id, {
                  order_type: orderType,
                  order_from: orderFrom,
                  store: storeSelect,
                  order_number: orderNumber,
                  date: dateSelect,
                  status: statusOrder,
                  tracking_service: trackingService ?? "",
                  tracking_number: trackingNumber ?? "",
                  client_name: clientName,
                  client_phone: clientPhone,
                  client_email: clientEmail,
                  full_address: fullAddress ?? "",
                  location_x: locationXAddress ?? "",
                  location_y: locationYAddress ?? "",
                  city: cityAddress ?? "",
                  state: stateAddress ?? "",
                  zip_code: zipCodeAddress ?? "",
                  country: countryAddress ?? "",
                  total_amount: totalAmount,
                  items: orderItemsNew,
                  itemsdelete: orderItemsDelete,
                  specialized_note: specializedNote,
                  preparing_size: preparingSize ?? "",
                  preparing_weight: preparingWeight ?? "",
                })
              ).then(() => {});
              setIsAddOrder(false);
              setEventType("");
              setLoadEvent(false);
            }
          }}
          onCancel={() => {
            setIsAddOrder(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        {/* buttom dash */}
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default EditOrderScreen;
