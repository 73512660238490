import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  createNewUserReducer,
  getDetailUserReducer,
  getProfileUserReducer,
  updateDetailUserReducer,
  updateProfileUserReducer,
  userLoginReducer,
  usersListReducer,
} from "./reducers/userReducers";
import {
  clientListReducer,
  createNewClientReducer,
  deleteClientReducer,
  detailClientReducer,
  updateClientReducer,
} from "./reducers/clientReducers";

import {
  checkedOrderReducer,
  confirmOrderReducer,
  confirmShippedOrderReducer,
  createNewOrderReducer,
  deleteOrderReducer,
  detailOrderReducer,
  itemsOrderReducer,
  orderListClientReducer,
  orderListReducer,
  updateOrderReducer,
} from "./reducers/orderReducers";
import {
  addEmployeStoreReducer,
  addStoreReducer,
  deleteEmployeStoreReducer,
  deleteStoreReducer,
  detailStoreReducer,
  employeDisponibleStoreListReducer,
  employeStoreListReducer,
  storeListReducer,
  updateStoreReducer,
} from "./reducers/storeReducers";
import {
  createNewProductReducer,
  deleteProductReducer,
  detailProductReducer,
  productListReducer,
  updateProductReducer,
} from "./reducers/productReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  //
  clientList: clientListReducer,
  createNewClient: createNewClientReducer,
  detailClient: detailClientReducer,
  updateClient: updateClientReducer,
  deleteClient: deleteClientReducer,
  //

  usersList: usersListReducer,
  createNewUser: createNewUserReducer,
  getProfileUser: getProfileUserReducer,
  updateProfileUser: updateProfileUserReducer,
  getDetailUser: getDetailUserReducer,
  updateDetailUser: updateDetailUserReducer,

  //
  orderList: orderListReducer,
  createNewOrder: createNewOrderReducer,
  confirmOrder: confirmOrderReducer,
  detailOrder: detailOrderReducer,
  itemsOrder: itemsOrderReducer,
  confirmShippedOrder: confirmShippedOrderReducer,
  updateOrder: updateOrderReducer,
  orderListClient: orderListClientReducer,
  deleteOrder: deleteOrderReducer,
  checkedOrder: checkedOrderReducer,
  //

  storeList: storeListReducer,
  deleteStore: deleteStoreReducer,
  detailStore: detailStoreReducer,
  updateStore: updateStoreReducer,
  employeStoreList: employeStoreListReducer,
  deleteEmployeStore: deleteEmployeStoreReducer,
  employeDisponibleStoreList: employeDisponibleStoreListReducer,
  addEmployeStore: addEmployeStoreReducer,
  addStore: addStoreReducer,
  //
  productList: productListReducer,
  deleteProduct: deleteProductReducer,
  createNewProduct: createNewProductReducer,
  detailProduct: detailProductReducer,
  updateProduct: updateProductReducer,

  //

  //
});

const userInfoFromStorage = localStorage.getItem("userInfoStoreBrass")
  ? JSON.parse(localStorage.getItem("userInfoStoreBrass"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
